<template>
  <div class="modal default-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a
            href=""
            class="close inner"
            @click.prevent="$router.push($route.path)"
          ></a>

          <div class="modal-content">
            <h3>{{ $t("orders.details_order") }}</h3>

            <form class="payment-form">
              <p class="form-text">
                {{ $t("orders.paid_success_description") }}
              </p>

              <div class="field-row">
                <p class="label">
                  {{
                    item.paymentType === "content"
                      ? $t("orders.content")
                      : $t("orders.section")
                  }}
                </p>
                <p class="read-only-input">
                  {{
                    item.paymentType === "content"
                      ? item.title
                      : item.collections
                      ? item.collections[0].name
                      : ""
                  }}
                </p>
              </div>

              <div class="field-row">
                <p class="label">{{ $t("orders.price") }}</p>
                <p class="price">{{ item.price }} ₽</p>
              </div>

              <div class="buttons one-button">
                <input
                  type="submit"
                  name="cancel"
                  class="success"
                  :value="$t('orders.button_success_pay')"
                  @click.prevent="$router.push($route.path)"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import events from "@/mixins/events";

export default {
  name: "SuccessPaymentContent",
  props: ["item"],
  mixins: [events],
  methods: {
    ...mapActions("order", {
      completeOrderGuid: "completeOrderGuidBuyContent"
    })
  },
  async mounted() {
    await this.completeOrderGuid({
      guid: this.$route.query.guid,
      state: "completed"
    }).then(async personalData => {
      await this._buyItemEvent(this.item, {
        ...personalData,
        status: "Success"
      });
    });
  }
};
</script>

<style scoped></style>
