<template>
  <div class="modal default-modal modal-open" v-if="show">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a
            href="#"
            class="close inner"
            @click.prevent="$emit('closeModal', true)"
          ></a>

          <div class="modal-content">
            <h3>{{ $t("orders.details_order") }}</h3>

            <form class="payment-form">
              <div class="field-row">
                <p class="label">
                  {{
                    item.paymentType === "content"
                      ? $t("orders.content")
                      : $t("orders.section")
                  }}
                </p>
                <p class="read-only-input">
                  {{
                    item.paymentType === "content"
                      ? item.title
                      : item.collections
                      ? item.collections[0].name
                      : ""
                  }}
                </p>
              </div>

              <div class="field-row">
                <p class="label">{{ $t("orders.price") }}</p>
                <p class="price">{{ item.price }} ₽</p>
              </div>

              <div class="field-row">
                <label for="name">{{ $t("orders.name") }}</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  :class="{ is_invalid: errors.name }"
                  v-model="form.name"
                  placeholder="Ivan Ivanov"
                />
                <p class="error-description" v-show="errors.name">
                  {{ $t("orders.empty name error") }}
                </p>
              </div>

              <p class="form-text" v-html="$t('orders.paid_description')"></p>

              <div class="field-row">
                <label for="email"
                  >{{ $t("orders.email") }}<span class="required"></span
                ></label>
                <input
                  type="text"
                  name="email"
                  :class="{ is_invalid: errors.email }"
                  placeholder="example@example.com"
                  id="email"
                  v-model="form.email"
                />
                <p class="error-description" v-show="errors.email">
                  <span v-if="!form.email.length">
                    {{ $t("orders.empty email error") }}
                  </span>
                  <span v-else>
                    {{ $t("orders.wrong email error") }}
                  </span>
                </p>
              </div>

              <div class="field-row">
                <label for="phone"
                  >{{ $t("orders.phone") }}<span class="required"></span
                ></label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autocomplete="nope"
                  :class="{ is_invalid: errors.phone }"
                  placeholder="79031234567"
                  v-model="form.phone"
                />
                <p class="error-description" v-show="errors.phone">
                  <span v-if="!form.phone.length">
                    {{ $t("orders.empty phone error") }}
                  </span>
                  <span v-else>
                    {{ $t("orders.wrong phone error") }}
                  </span>
                </p>
              </div>

              <div class="buttons">
                <input
                  type="submit"
                  name="cancel"
                  class="cancel"
                  :value="$t('orders.button_cancel')"
                  @click.prevent="$emit('closeModal', true)"
                />
                <input
                  type="submit"
                  name="cancel"
                  :class="{ buy: !validateForm, success: validateForm }"
                  @click.prevent="validateAndPayment"
                  :value="$t('orders.button_pay')"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import events from "@/mixins/events";

export default {
  name: "PaymentContent",
  props: ["show", "item"],
  mixins: [events],
  methods: {
    ...mapActions("order", {
      setOrder: "setOrderBuyContent",
      cancelOrder: "cancelOrder",
      completeOrderGuid: "completeOrderGuid"
    }),
    ...mapMutations("order", {
      setEntityOrder: "SET_ENTITY"
    }),
    async validateAndPayment() {
      this.errors.email = !(this.form.email.length > 1 && this.emailValidate);
      this.errors.phone = !(this.form.phone.length > 1 && this.phoneValidate);

      if (!this.errors.email && !this.errors.phone) {
        this.setEntityOrder(["showModalBuyContent", false]);
        const order = {
          price: this.item.price,
          contentId: this.item.id,
          content: {
            paymentType: this.item.paymentType,
            ids: [],
            personalData: {
              email: this.form.email,
              phone: this.form.phone,
              name: this.form.name
            }
          }
        };
        if (this.item.paymentType === "content") {
          order.content.ids = [this.item.id];
        } else if (this.item.paymentType === "collection") {
          order.content.ids = this.item.collections.map(
            mov => mov.collectionId
          );
        }

        const response = await this.setOrder(order);
        const payload = {
          amount: {
            value: this.item.price,
            currency: "RUB"
          },
          paymentMethod: {
            type: "bankCard"
          },
          successUri:
            window.location.href.split("?")[0] +
            "?payment=success&guid=" +
            response.guid,
          errorUri:
            window.location.href.split("?")[0] +
            "?payment=error&guid=" +
            response.guid,
          description: "Buy Content"
        };
        const personalData = {
          email: this.form.email,
          phone: this.form.phone,
          name: this.form.name
        };
        if (personalData) {
          const receipt = {
            items: [
              {
                quantity: 1,
                amount: {
                  value: this.item.price,
                  currency: "RUB"
                },
                vatCode: 4, //TODO: what's real value?
                description: "Buy Content"
              }
            ]
          };
          if (personalData.email) {
            Object.assign(receipt, { email: personalData.email });
          }
          if (personalData.phone) {
            Object.assign(receipt, { phone: personalData.phone });
          }
          if (personalData.name) {
            Object.assign(receipt, { name: personalData.name });
          }
          Object.assign(payload, { receipt });
        }
        await this._buyItemEvent(this.item, {
          ...personalData,
          status: "MakePayment"
        });
        try {
          const data = await this.axios
            .post("/offline-payment-accumulator/payments/create", payload)
            .then(response => response.data.result);
          if (data.confirmationUri) {
            window.location.href = data.confirmationUri;
          }
        } catch (e) {
          window.location.href =
            window.location.href.split("?")[0] +
            "?payment=error&guid=" +
            response.guid;
        }
      }
    }
  },
  computed: {
    emailValidate() {
      let emailValidator = new RegExp(
        "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
      );
      let validEmail = emailValidator.test(this.form.email);
      if (!validEmail) {
        return false;
      }

      return true;
    },
    validateForm() {
      if (
        this.form.email.length > 1 &&
        this.form.phone.length > 10 &&
        this.form.name.length > 1
      ) {
        return true;
      }
      return false;
    },
    phoneValidate() {
      let validator = new RegExp("^[0-9]+$");
      let test = validator.test(this.form.phone);
      if (!test || this.form.phone.length < 10) {
        return false;
      }

      return true;
    }
  },
  data: () => {
    return {
      form: {
        phone: "",
        email: "",
        name: ""
      },
      errors: {
        phone: "",
        email: "",
        name: ""
      }
    };
  }
};
</script>

<style scoped></style>
